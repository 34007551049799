.header-main-container {
    width: 100%;
}

.header-container {
    height: 55px;
    width: 100vw;
    background-color: #3A4DFD;
    vertical-align: middle;
    display: table-cell;
}

.header-logo {
    height: 33.12px;
    width: 241.99;
}

.header-menu-container {
    height: 126px;
    width: 100vw;
    padding: 48.84px 69px 44.03px 58px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-container .title {
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.25px;
    line-height: 20px;
    margin-bottom: 0 !important;
}

.header-menu-item {
    font-size: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    line-height: 2.5;
    padding-inline: 20px;
    cursor: pointer;
}

.header-btn {
    color: white;
    width: 214px;
    height: 49px;
    font-size: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 68px;
    background: #3A4DFD;
    text-align: center;
    border: none;
    outline: none;
    margin-left: 25px;
    margin-bottom: 25px;
}

.footer-title {
    color: #002FA3;
    font-size: 19.2px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 27.84px;
    letter-spacing: 0.202px;
}

.footer-link {
    color: #002FA3;
    font-size: 16.168px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    line-height: 29.204px;
}

.footer-text {
    color: #002FA3;
    font-size: 16px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0.25px;
    text-transform: capitalize;
    margin-top: 36.56px;
    margin-bottom: 34px;
}

.social-icon {
    cursor: pointer;
    height: 30px;
    margin-right: 38px;
}

.footer-container {
    width: 100%;
    background-color: white !important;
    padding-top: 78px;
    padding-bottom: 129px;
}

.nav-link {
    color: #0C1431;
    font-size: 18px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-inline: 20px !important;
}


.navbar-toggler {
    padding: 0 3px;
    font-size: medium;
    border: none;
    outline: none;
}

.navbar-toggler:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

button:focus:not(:focus-visible) {
    border-width: 1px;
}

@media (max-width:768px) {
    .header-menu-container {
        height: 80px;
        width: 100vw;
        padding: 28.84px 10px 24.03px 28px;
    }

    .header-btn {
        margin-left: 0 !important;
        margin-top: 10px;
        width: 200px;
        height: 50px;
        border-radius: 1137.499px;
        font-size: 16px;
    }

    .header-menu-item {
        padding-inline: 0px;
    }
}