.my-10 {
  margin-block: 6rem;
}

.pointer {
  cursor: pointer !important;
}

a {
  text-decoration: none !important;
}

body {
  background-color: #F7F7FC !important;
  font-family: "Poppins";
}

.text-theme-purple {
  color: #002FA3 !important;
}

.mobile-only {
  display: none !important;
}

@media (max-width:768px) {

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-bottom: 20px;
  }

  .mobile-only {
    display: block !important;
  }

  .web-only {
    display: none !important;
  }
}