.content-container-first-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 125px;
}

.content-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.title-1 {
    font-size: 45px;
    font-family: 'Montserrat';
    font-weight: bold;
    color: #3A4DFD;
}

.text-purpule {
    color: #002FA3;
}

.discription-1 {
    color: #002FA3;
    font-family: "DM Sans";
    font-size: 28px;
    line-height: 56px;
    opacity: 0.7;
    margin-top: 22.5px;
    margin-bottom: 38.52px;
}

.theme-button {
    width: 285px;
    height: 70px;
    border-radius: 1137.499px;
    background: #3A4DFD;
    font-size: 24px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    text-transform: capitalize;
    color: #fff;
    border: none;
    outline: none;
    text-align: center;
}

.content-nunito-bold-32 {
    color: #000743;
    font-size: 32px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
}

.open-sans-20 {
    color: #06004D;
    font-size: 20px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 44px;
    opacity: 0.7;
}

.get-started {
    background-image: url('../../assets/images/get_started_bg_1.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 490px;
    width: 100%;
    background-position: center;
    margin-bottom: 140px;
    border-radius: 30px;
    overflow: hidden;
    padding: 78px 60px;
}

.get-started-head {
    color: white;
    font-size: 47.09px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 800;
    line-height: 66.7px;
    opacity: 0.9;
}

.hr-container {
    margin-block: 25.99px;
    display: flex;
    flex-direction: row;
}

.hr-1 {
    width: 200px;
    height: 6.501px;
    background-color: #fff;
    margin-right: 10px;
    border-radius: 3.5px;
}

.hr-2 {
    width: 20px;
    height: 6.501px;
    background-color: #fff;
    border-radius: 3.5px;
}

.get-started-description {
    color: white;
    font-size: 22px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    line-height: 42.5px;
    width: 542px;
    margin-bottom: 43px;
}

.get-started-btn {
    background-color: white;
    height: 64px;
    width: 296px;
    color: #002FA3;
    text-align: center;
    font-size: 24px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    line-height: 16.153px;
    text-transform: capitalize;
}

/* power by section */

.power-by-container {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 72px;
    margin-bottom: 87px;
    opacity: 0.7;
}


.power-by-text {
    color: #002FA3;
    font-size: 28.532px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    line-height: 56.046px;
    margin-right: 22px;
}

.power-by-container img {
    height: 44px;
    width: 228.54px;
}

/* checkout section */
.check-out-text {
    text-align: center;
    font-size: 32px;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    line-height: 56px;
    margin-bottom: 12px;
}

.video-tv-image {
    cursor: pointer;
    height: 682.2px;
    width: 1166.144px;
    margin-bottom: 150px;
}

.mb-140-px {
    margin-bottom: 140px;
}

.mb-158-px {
    margin-bottom: 158px;
}

.image-1 {
    width: 710px;
    height: auto;
}

.image-2 {
    width: 95%;
    height: auto;
}

.image-3 {
    height: 546.37px;
    width: 643px;
}

.image-4 {
    height: 499px;
    width: 594px;
}

.image-5 {
    width: 574px;
    height: 500px;
}

@media (max-width:768px) {
    .title-1 {
        font-size: 24px;
    }

    .discription-1 {
        font-size: 20px;
        line-height: 38px;
        margin-bottom: 30px;
        z-index: 0;
    }

    .image-1,
    .image-3,
    .image-4,
    .image-5 {
        width: 100%;
        height: auto;
    }

    .image-2 {
        margin-top: 50px;
        width: 100%;
    }

    .mb-140-px,
    .mb-158-px {
        margin-bottom: 80px;
    }

    .get-started {
        height: 160px;
        padding: 20px 20px;
        border-radius: 15px;
        width: 90%;
        margin-bottom: 80px;
    }

    .get-started-head {
        font-size: 20px;
        line-height: 30.7px;
    }

    .get-started-description {
        color: white;
        font-size: 8px;
        line-height: 16px;
        width: 190px;
        margin-bottom: 10px;
    }

    .get-started-btn {
        height: 25px !important;
        font-size: 10px !important;
        padding-inline: 10px !important;
        width: 120px !important;
    }

    .hr-container {
        margin-block: 10.99px;
    }

    .hr-1 {
        width: 100px;
        height: 3.501px;
        margin-right: 5px;
    }

    .hr-2 {
        width: 10px;
        height: 3.501px;
    }

    .theme-button {
        width: 200px;
        height: 50px;
        border-radius: 1137.499px;
        font-size: 16px;
    }

    .power-by-container {
        padding-left: 40px !important;
        padding-right: 40px !important;
        margin-top: 42px;
        margin-bottom: 57px;
    }

    .power-by-text {
        font-size: 20px;
        line-height: 38px;
        margin-right: 22px;
    }

    .power-by-container img {
        height: 30px !important;
        width: auto;
    }

    .check-out-text {
        font-size: 26px;
    }

    .video-tv-image {
        height: auto;
        width: 100%;
        margin-bottom: 80px;
    }

    .content-nunito-bold-32 {
        font-size: 24px;
        line-height: 45px;
    }

    .open-sans-20 {
        font-size: 18px;
        line-height: 42px;
    }
}